import React from "react";

import {
  Container,
  Col,
  Row,
  InputGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import PolicyModal from "./PolicyModal/PolicyModal.js";
import TermsModal from "./TermsModal/TermsModal.js";
import classes from "./HeroLayoutsType7th.module.css";
import page_7_heart from "../../../img/page_7_heart.png";
import axios from "axios";
// import intercom from "../../../lib/intercom.js";

function HeroLayoutsTypeSecond(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [showPolicy, setShowPolicy] = React.useState(false);
  const [showTerms, setShowTerms] = React.useState(false);
  const toggle = () => {
    setShowModal(!showModal);
    setShowPolicy(false);
    setShowTerms(false);
  };
  const showPolicyMethod = () => {
    toggle();
    setShowPolicy(true);
    setShowTerms(false);
  };
  const showTermsMethod = () => {
    // toggle();
    // setShowTerms(true);
    // setShowPolicy(false);
  };

  const boot = () => {
    window.Intercom("boot", {
      app_id: window.APP_ID,
      //Website visitor so may not have any user related info
    });
  };

  const subscribe = () => {};

  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
        style={{ backgroundColor: "#fafafa" }}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col className={classes.slider}>
                <Row>
                  <Col
                    lg={{ size: 6 }}
                    md={{ size: 6 }}
                    sm={6}
                    style={{ fontSize: "14px" }}
                  >
                    <h4 className={`text-left ${classes.topNone}`}>
                      <span className={`${classes.fontStyle} `}>
                        EngageMessage
                      </span>
                    </h4>
                    <p className={`normal-text description text-left`}>
                      <a
                        href="https://apps.apple.com/app/id1559846990"
                        className={`${classes.fontStyle}`}
                      >
                        EngageMessage for iOS
                      </a>
                    </p>
                    <p
                      className={`normal-text description text-left ${classes.topPadding}`}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.engagemessage.android.em"
                        className={`${classes.fontStyle}`}
                      >
                        EngageMessage for Android
                      </a>
                    </p>
                    <p
                      className={`normal-text description text-left ${classes.topPadding}`}
                    >
                      <a
                        href="https://faq.engagemessage.com/en"
                        target="_blank"
                        className={`${classes.fontStyle}`}
                      >
                        FAQ
                      </a>
                    </p>
                    <p
                      className={`normal-text description text-left ${classes.topPadding} ${classes.contactPadding}`}
                    >
                      <a className={`${classes.fontStyle}`} onClick={boot}>
                        Contact Us
                      </a>
                    </p>
                    <p
                      className={`normal-text description text-left ${classes.bottom}`}
                    >
                      <a className={`${classes.fontStyle}`}>
                        Made with{" "}
                        <img
                          src={page_7_heart}
                          style={{ height: "auto", width: "20px" }}
                        ></img>{" "}
                        in Seattle, WA
                      </a>
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 5, offset: 1 }}
                    sm={6}
                    className={`left-column d-flex flex-column ${classes.xsLeft}`}
                  >
                    <h4
                      className={`text-left ${classes.topNone} ${classes.normalFontFamily} `}
                    >
                      <span className={`${classes.fontStyle} `}>
                        SUBSCRIBE TO OUR NEWSLETTER
                      </span>
                    </h4>
                    <p
                      className={`normal-text description text-left ${classes.fontStyle} ${classes.normalFontFamily} ${classes.emaildiscription}`}
                    >
                      The latest EngageMessage news, articles, and resources,
                      sent straight to your inbox every month.
                    </p>
                    <Row style={{ width: "100%", paddingBottom: "48px" }}>
                      <Col lg={8} md={8} sm={8} xs={8}>
                        <InputGroup style={{ margin: "5px 0" }}>
                          <Input
                            placeholder="Your email address"
                            style={{
                              backgroundColor: "#e6e6e6",
                              height: "50px",
                              border: "none",
                              fontFamily: '"Inter", "Arial", sans-serif',
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ paddingLeft: 0 }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#e72955",
                            margin: "5px 0",
                            height: "50px",
                            minWidth: "95px",
                            border: "none",
                            fontWeight: "700",
                            fontFamily: '"Inter", "Arial", sans-serif',
                          }}
                          onClick={subscribe}
                        >
                          Subscribe
                        </Button>{" "}
                      </Col>
                    </Row>
                    <p
                      className={`normal-text description text-left ${classes.fontStyle} ${classes.shrinkGrey} ${classes.bottom}`}
                      style={{ marginTop: "15px" }}
                    >
                      Copyright ©2022 EngageMessage -{" "}
                      <a
                        href="./terms.html"
                        target="_blank"
                        className={`${classes.fontStyle}`}
                      >
                        Terms of Use
                      </a>{" "}
                      -{" "}
                      <a
                        href="./policy.html"
                        target="_blank"
                        className={`${classes.fontStyle}`}
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {" "}
          {showPolicy ? "PRIVACY POLICY" : "TERMS OF USE"}
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <PolicyModal show={showPolicy}></PolicyModal>
          <TermsModal show={showTerms}></TermsModal>
        </ModalBody>
      </Modal> */}
    </React.Fragment>
  );
}
export default HeroLayoutsTypeSecond;
