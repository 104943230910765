import React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "reactstrap";
import page_1_appstore from "../../../img/page_1_appstore.png";
import page_1_googleplay from "../../../img/page_1_googleplay.png";
import page_1_webapp from "../../../img/page_1_webapp.png";
import page_1_side_left from "../../../img/page_1_side_left.png";
import page_1_side_right from "../../../img/page_1_side_right.png";
import Header from "./Header/Header";
import classes from "./HeroLayoutsType1th.module.css";
import {
  LogoDavidoff,
  LogoKendrabeauty,
  LogoLevis,
  LogoNordstrom,
  LogoSheraton,
  LogoTheessentials,
} from "../../../img/logo";

const titleText =
  "Boost sales with Email, SMS, Instagram and Facebook Marketing Automations";
const descriptionText =
  "Engage the right customer with the right message at the right time.";
const subDescriptionText = "Set up in minutes. No credit card required.";
const firstLinkText = "Get Started";
const secondLinkText = "Learn More";

const firstLink = "/example#1";
const secondLink = "/example#2";

function HeroLayoutsTypeFifth(props) {
  const onLinksClickHandler = (event) => {
    event.preventDefault();
    let element = event.currentTarget.getBoundingClientRect();
    window.scrollTo({ bottom: element.bottom + 100, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Fade top triggerOnce={true}>
              <Header dark={props.dark ? true : false} />
            </Fade>
          </Container>
          <Container>
            <Row className={classes.contentRow}>
              <Col lg="1" md="1" className={classes.pageSide}>
                <img
                  src={page_1_side_left}
                  alt=""
                  style={{ width: "auto", height: "30vh", maxHeight: "250px" }}
                />
              </Col>
              <Col lg="10" md="10" className="mx-auto text-center">
                <Fade top delay={100} triggerOnce={true}>
                  <h1 className={classes.titleText}>{titleText}</h1>
                </Fade>
                <Fade top delay={150} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {descriptionText}
                  </p>
                </Fade>
                <div className={classes.appLinks}>
                  <a
                    href="https://apps.apple.com/app/id1559846990"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className={classes.appLink}
                      src={page_1_appstore}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.engagemessage.android.em"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className={classes.appLink}
                      src={page_1_googleplay}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://desktop.engagemessage.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className={classes.appLink}
                      src={page_1_webapp}
                      alt=""
                    />
                  </a>
                </div>
                <Row>
                  <Col
                    lg="12"
                    md="12"
                    className={`mx-auto ${classes.linkSection}`}
                  >
                    <div>
                      <div className={classes.subDescriptionText}>
                        {subDescriptionText}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#000000",
                          lineHeight: "29px",
                          marginTop: 32,
                          marginBottom: 24,
                        }}
                      >
                        Trusted by top brands
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: 24,
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ width: "auto" }}
                          height={44}
                          src={LogoNordstrom}
                          alt=""
                        />
                        <img
                          style={{ width: 98 }}
                          height={44}
                          src={LogoDavidoff}
                          alt=""
                        />
                        <img
                          style={{ width: "auto" }}
                          height={44}
                          src={LogoLevis}
                          alt=""
                        />
                        <img
                          style={{ width: "auto" }}
                          height={44}
                          src={LogoTheessentials}
                          alt=""
                        />
                        <img
                          style={{ width: "auto" }}
                          height={44}
                          src={LogoKendrabeauty}
                          alt=""
                        />
                        <img
                          style={{ width: "auto" }}
                          height={44}
                          src={LogoSheraton}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg="1" md="1" className={classes.pageSide}>
                <div style={{ float: "right" }}>
                  <img
                    src={page_1_side_right}
                    alt=""
                    style={{
                      width: "auto",
                      height: "30vh",
                      maxHeight: "250px",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HeroLayoutsTypeFifth;
