import React from "react";

import HeroLayoutsType1th from "./layouts/HeroLayouts/HeroLayoutsType1th/HeroLayoutsType1th";
import HeroLayoutsType2th from "./layouts/HeroLayouts/HeroLayoutsType2th/HeroLayoutsType2th";
import HeroLayoutsType2hth from "./layouts/HeroLayouts/HeroLayoutsType2hth/HeroLayoutsType2hth";
import HeroLayoutsType2ith from "./layouts/HeroLayouts/HeroLayoutsType2ith/HeroLayoutsType2ith";
import HeroLayoutsType3th from "./layouts/HeroLayouts/HeroLayoutsType3th/HeroLayoutsType3th";
import HeroLayoutsType4th from "./layouts/HeroLayouts/HeroLayoutsType4th/HeroLayoutsType4th";
import HeroLayoutsType45th from "./layouts/HeroLayouts/HeroLayoutsType45th/HeroLayoutsType5th";
import HeroLayoutsType5th from "./layouts/HeroLayouts/HeroLayoutsType5th/HeroLayoutsType5th";
import HeroLayoutsType6th from "./layouts/HeroLayouts/HeroLayoutsType6th/HeroLayoutsType6th";
import HeroLayoutsType7th from "./layouts/HeroLayouts/HeroLayoutsType7th/HeroLayoutsType7th";

function App() {
  return (
    <div>
      <HeroLayoutsType1th />
      <HeroLayoutsType2th />
      <HeroLayoutsType2hth />
      <HeroLayoutsType2ith />
      <HeroLayoutsType3th />
      <HeroLayoutsType4th />
      <HeroLayoutsType45th />
      <HeroLayoutsType6th />
      <HeroLayoutsType5th />
      <HeroLayoutsType7th />
    </div>
  );
}

export default App;
